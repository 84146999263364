import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import ContactForm from "../components/ContactForm";

import { supportedLanguages } from '../i18n';

import classNames from 'classnames';

import plans from '../../plans.json';

import Stars from '../components/Stars';

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import Swiper from 'swiper/bundle';

import logoLobbyze from 'url:../assets/logo_lobbyze.svg';
import star from 'url:../assets/star.svg';
import notebookBanner1 from 'url:../assets/notebook-banner1.png';
import notebookBanner2 from 'url:../assets/notebook-banner2.png';
import notebookBanner3 from 'url:../assets/notebook-banner3.png';

import pokerStars from 'url:../assets/poker-stars.png';
import ggPoker from 'url:../assets/ggpoker.png';
import partyPoker from 'url:../assets/party-poker.png';
import _888poker from 'url:../assets/888-poker.png';
import wpn from 'url:../assets/wpn.png';
import bodog from 'url:../assets/bodog.png';
import ipoker from 'url:../assets/ipoker.png';
import chico from 'url:../assets/chico.png';
import winamax from 'url:../assets/winamax.png';
import _4bet from 'url:../assets/client_logos/4bet.png';
import cardroom from 'url:../assets/client_logos/Cardroom.png';
import evolution from 'url:../assets/client_logos/Evolution.png';
import full from 'url:../assets/client_logos/Full.png';
import heroes from 'url:../assets/client_logos/Heroes.png';
import inside from 'url:../assets/client_logos/Inside.png';
import insight from 'url:../assets/client_logos/Insight.png';
import suits from 'url:../assets/client_logos/logo-suits.png';
import lobos from 'url:../assets/client_logos/lobos-logo.png';
import panda from 'url:../assets/client_logos/Panda.png';
import step from 'url:../assets/client_logos/Step.png';
import midas from 'url:../assets/client_logos/Midas.png';

import notebookFeatures from 'url:../assets/notebook-features.png';
import tela1 from 'url:../assets/tela1.png';
import tela2 from 'url:../assets/tela2.png';
import tela3 from 'url:../assets/tela3.png';
import tela4 from 'url:../assets/tela4.png';

import thumbVideo from 'url:../assets/thumb-video.png';

import telefone from 'url:../assets/telefone.svg';
import email from 'url:../assets/email.svg';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/sea/index.css';

const Home = () => {
  const { t, i18n } = useTranslation();

  const [fixedNavbar, setFixedNavbar] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalUrl, setModalUrl] = useState('');

  const [showLanguageTop, setShowLanguageTop] = useState(false);
  const [showLanguageBottom, setShowLanguageBottom] = useState(false);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleChangeLanguage = (lang) => () => {
    i18n.changeLanguage(lang);
  };

  const handleActiveTab = (index) => () => {
    setActiveTab(index);
  };

  const handleModal = (url) => () => {
    setModalOpen(!!url);
    setModalUrl(url ?? '');
    var player = videojs('my-video');
    if(!!url){
      player.pause();
      player.src(url);
      player.fluid(true)
      player.controls(true)
      player.addClass('vjs-theme-sea')
      player.load();
      player.play();
    } else {
      player.pause();
    }
  };

  const toggleLanguageMenuTop = () => {
    setShowLanguageTop((oldValue) => !oldValue);
  };

  const toggleLanguageMenuBottom = () => {
    setShowLanguageBottom((oldValue) => !oldValue);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((oldValue) => !oldValue);
  };

  useEffect(() => {
    const listener = () => {
      if (document.body.scrollTop > 64 || document.documentElement.scrollTop > 64) {
        if (!fixedNavbar) setFixedNavbar(true);
      } else {
        if (fixedNavbar) setFixedNavbar(true);
      }
    };

    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  useEffect(() => {
    new Swiper('.banner .swiper-container', {
      loop: true,
      simulateTouch: false,
      speed: 700,
      autoHeight: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.banner .pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.banner .next',
        prevEl: '.banner .prev',
      },
    });
  }, []);

  return (
    <div className={classNames({ 'o-hidden': modalOpen || mobileMenuOpen })}>
      <nav className={classNames('navbar', { fixed: fixedNavbar })}>
        <div className="container">
          <a className="navbar__logo" href="/">
            <img src={logoLobbyze} alt={t('logoLobbyze')} />
          </a>
          <aside className={classNames('navbar__links', { open: mobileMenuOpen })}>
            <ul className="navbar__links__list">
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="#features">{t('features')}</a>
              </li>
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="/plans">{t('pricing')}</a>
              </li>
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="#contato">{t('contact')}</a>
              </li>
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="#clients">{t('clients')}</a>
              </li>
              <li className="navbar__links__list__link">
                <a href="/faq">
                  {t('FAQ')}
                </a>
              </li>
              <li className="navbar__links__list__criar-conta">
                <a href="https://app.lobbyze.com/">
                  {t('tryItFree')}
                </a>
              </li>
              <li className="navbar__links__list__link" onClick={() => setMobileMenuOpen(false)}>
                <a href="https://app.lobbyze.com">{t('login')}</a>
              </li>
              <li
                className={classNames('navbar__links__list__language', { open: showLanguageTop })}
                onClick={toggleLanguageMenuTop}
              >
                <button className="navbar__links__list__language__atual" type="button">
                  <span>{i18n.language}</span>
                </button>
                <div className="navbar__links__list__language__outros">
                  {supportedLanguages
                    .filter((lang) => lang != i18n.language)
                    .map((lang) => (
                      <a key={lang} onClick={handleChangeLanguage(lang)}>
                        {lang}
                      </a>
                    ))}
                </div>
              </li>
            </ul>
          </aside>
          <button
            className={classNames('navbar__menu-icon', { open: mobileMenuOpen })}
            type="button"
            onClick={toggleMobileMenu}
          >
            <span className="navbar__menu-icon__bar"></span>
            <span className="navbar__menu-icon__bar"></span>
            <span className="navbar__menu-icon__bar"></span>
          </button>
        </div>
      </nav>

      <section className="banner">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <div className="swiper-slide banner__slide">
              <div className="container">
                <aside className="banner__slide__texto">
                  <h2>
                    <Trans i18nKey="slide.0.title" components={{ b: <b /> }} />
                  </h2>
                  <p>{t('slide.0.description')}</p>
                  <div>
                    <a className="button" href="https://app.lobbyze.com">
                      {t('mainAction')}
                    </a>
                    <aside className="banner__slide__texto__destaque">
                      <figure>
                        <img src={star} alt="estrela" />
                      </figure>
                      <span>{t('mainActionSide')}</span>
                    </aside>
                  </div>
                </aside>
                <figure className="banner__slide__image">
                  <img src={notebookBanner1} alt="Notebook flutuando" />
                </figure>
              </div>
            </div>
            <div className="swiper-slide banner__slide">
              <div className="container">
                <aside className="banner__slide__texto">
                  <h2>
                    <Trans i18nKey="slide.1.title" components={{ b: <b /> }} />
                  </h2>
                  <p>{t('slide.1.description')}</p>
                  <div>
                    <a className="button" href="https://app.lobbyze.com">
                      {t('mainAction')}
                    </a>
                    <aside className="banner__slide__texto__destaque">
                      <figure>
                        <img src={star} alt="estrela" />
                      </figure>
                      <span>{t('mainActionSide')}</span>
                    </aside>
                  </div>
                </aside>
                <figure className="banner__slide__image">
                  <img src={notebookBanner2} alt="Notebook flutuando" />
                </figure>
              </div>
            </div>
            <div className="swiper-slide banner__slide">
              <div className="container">
                <aside className="banner__slide__texto">
                  <h2>
                    <Trans i18nKey="slide.2.title" components={{ b: <b /> }} />
                  </h2>
                  <p>{t('slide.2.description')}</p>
                  <div>
                    <a className="button" href="https://app.lobbyze.com">
                      {t('mainAction')}
                    </a>
                    <aside className="banner__slide__texto__destaque">
                      <figure>
                        <img src={star} alt="estrela" />
                      </figure>
                      <span>{t('mainActionSide')}</span>
                    </aside>
                  </div>
                </aside>
                <figure className="banner__slide__image">
                  <img src={notebookBanner3} alt="Notebook flutuando" />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="banner__controls container">
          <div>
            <div className="banner__controls__controllers">
              <button className="prev" type="button">
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14.3982" cy="14.3982" r="14.3982" fill="white" fillOpacity="0.08" />
                  <path
                    d="M16 19L11 14L16 9"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="pagination"></div>
            <div className="banner__controls__controllers">
              <button className="next" type="button">
                <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle r="14.3982" transform="matrix(-1 0 0 1 15.1946 14.3982)" fill="white" fillOpacity="0.08" />
                  <path
                    d="M13.5928 19L18.5928 14L13.5928 9"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="second-sec" id="clients">
        <section className="logos sites">
          <div className="container">
            <header className="header-sec">
              <h5>{t('supported_sites')}</h5>
            </header>
          </div>
          <div className="container">
            <figure>
              <img src={pokerStars} alt="Pokerstars" class="limit-height" />
            </figure>
            <figure>
              <img src={ggPoker} alt="GGPoker" class="limit-height" />
            </figure>
            <figure>
              <img src={partyPoker} alt="Party poker" class="limit-height" />
            </figure>
            <figure>
              <img src={_888poker} alt="888 Poker" class="limit-width" />
            </figure>
            <figure>
              <img src={wpn} alt="WPN Network" class="limit-width" />
            </figure>
            <figure>
              <img src={bodog} alt="Bodog" class="limit-height" />
            </figure>
            <figure>
              <img src={ipoker} alt="iPoker" class="limit-height" />
            </figure>
            <figure>
              <img src={chico} alt="Chico Network" class="limit-width" />
            </figure>
            <figure>
              <img src={winamax} alt="Winamax" class="limit-width" />
            </figure>
          </div>
        </section>
        <section className="logos">
          <div className="container">
            <header className="header-sec">
              <h5>{t('our_clients')}</h5>
              <h4>{t('clientsTrust')}</h4>
            </header>
          </div>
          <div className="container">
            <figure>
              <img src={_4bet} alt="4bet" class="client_logo" />
            </figure>
            <figure>
              <img src={cardroom} alt="Cardroom" class="client_logo" />
            </figure>
            <figure>
              <img src={evolution} alt="Evolution" class="client_logo" />
            </figure>
            <figure>
              <img src={full} alt="Full" class="client_logo" />
            </figure>
            <figure>
              <img src={heroes} alt="Heroes" class="client_logo" />
            </figure>
            <figure>
              <img src={inside} alt="Inside" class="client_logo" />
            </figure>
            <figure>
              <img src={insight} alt="Insight" class="client_logo" />
            </figure>
            <figure>
              <img src={suits} alt="Suits" class="client_logo" />
            </figure>
            <figure>
              <img src={lobos} alt="Lobos" class="client_logo" />
            </figure>
            <figure>
              <img src={midas} alt="Midas" class="client_logo" />
            </figure>
            <figure>
              <img src={panda} alt="Panda" class="client_logo" />
            </figure>
            <figure>
              <img src={step} alt="Step" class="client_logo" />
            </figure>
          </div>
        </section>
        <section id="features" className="features">
          <div className="container">
            <aside className="features__screens">
              <figure className="features__screens__notebook">
                <div>
                  <img src={notebookFeatures} alt={t('Notebook')} width="100%" />
                </div>

                <figure className={classNames('features__screens__screen', { active: activeTab === 0 })}>
                  <img src={tela1} alt={t('screen')} width="100%" height="100%" />
                </figure>
                <figure className={classNames('features__screens__screen', { active: activeTab === 1 })}>
                  <img src={tela2} alt={t('screen')} width="100%" height="100%" />
                </figure>
                <figure className={classNames('features__screens__screen', { active: activeTab === 2 })}>
                  <img src={tela1} alt={t('screen')} width="100%" height="100%" />
                </figure>
                <figure className={classNames('features__screens__screen', { active: activeTab === 3 })}>
                  <img src={tela4} alt={t('screen')} width="100%" height="100%" />
                </figure>
                <figure className="features__screens__light">
                  <svg width="595" height="598" viewBox="0 0 595 598" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_f)">
                      <rect
                        x="200"
                        y="200.3"
                        width="194.819"
                        height="197.442"
                        rx="97.4095"
                        fill="url(#paint0_linear)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_f"
                        x="0"
                        y="0.300171"
                        width="594.819"
                        height="597.442"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur" />
                      </filter>
                      <linearGradient
                        id="paint0_linear"
                        x1="201.346"
                        y1="389.941"
                        x2="502.099"
                        y2="110.437"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#D190FF" />
                        <stop offset="0.4782" stopColor="#838CFF" />
                        <stop offset="1" stopColor="#42D5DD" />
                      </linearGradient>
                    </defs>
                  </svg>
                </figure>
              </figure>
            </aside>
            <aside className="features__content">
              <header className="header-sec">
                <h5>{t('features')}</h5>
                <h3>{t('learnFeatures')}</h3>
              </header>
              <dl className="features__content__tabs">
                <div onClick={handleActiveTab(0)} className={classNames({ active: activeTab === 0 })}>
                  <dt>
                    <span>01</span>
                    <h4>{t('featuresList.0.title')}</h4>
                  </dt>
                  <dd>
                    <p>{t('featuresList.0.description')}</p>
                    <button onClick={handleModal(i18n.language == 'en' ? 'https://lobbyze-public-misc.s3.us-east-2.amazonaws.com/lobbyze-tutorials/how_to_find_tournaments_en.mp4' : 'https://lobbyze-public-misc.s3.us-east-2.amazonaws.com/lobbyze-tutorials/how_to_find_tournaments_pt.mp4')} type="button">
                      {t('seeDemo')}
                    </button>
                  </dd>
                </div>
                <div onClick={handleActiveTab(1)} className={classNames({ active: activeTab === 1 })}>
                  <dt>
                    <span>02</span>
                    <h4>{t('featuresList.1.title')}</h4>
                  </dt>
                  <dd>
                    <p>{t('featuresList.1.description')}</p>
                    <button onClick={handleModal(i18n.language == 'en' ? 'https://lobbyze-public-misc.s3.us-east-2.amazonaws.com/lobbyze-tutorials/how_to_make_a_tier_en.mp4' : 'https://lobbyze-public-misc.s3.us-east-2.amazonaws.com/lobbyze-tutorials/how_to_make_a_tier_pt.mp4')} type="button">
                      {t('seeDemo')}
                    </button>
                  </dd>
                </div>
                <div onClick={handleActiveTab(2)} className={classNames({ active: activeTab === 2 })}>
                  <dt>
                    <span>03</span>
                    <h4>{t('featuresList.2.title')}</h4>
                  </dt>
                  <dd>
                    <p>{t('featuresList.2.description')}</p>
                    <button onClick={handleModal(i18n.language == 'en' ? 'https://lobbyze-public-misc.s3.us-east-2.amazonaws.com/lobbyze-tutorials/how_to_save_filters_en.mp4' : 'https://lobbyze-public-misc.s3.us-east-2.amazonaws.com/lobbyze-tutorials/how_to_save_filters_pt.mp4')} type="button">
                      {t('seeDemo')}
                    </button>
                  </dd>
                </div>
                <div onClick={handleActiveTab(3)} className={classNames({ active: activeTab === 3 })}>
                  <dt>
                    <span>04</span>
                    <h4>{t('featuresList.3.title')}</h4>
                  </dt>
                  <dd>
                    <p>{t('featuresList.3.description')}</p>
                    <button onClick={handleModal(i18n.language == 'en' ? 'https://lobbyze-public-misc.s3.us-east-2.amazonaws.com/lobbyze-tutorials/play_reports_en.mp4' : 'https://lobbyze-public-misc.s3.us-east-2.amazonaws.com/lobbyze-tutorials/play_reports_pt.mp4')} type="button">
                      {t('seeDemo')}
                    </button>
                  </dd>
                </div>
              </dl>
            </aside>
          </div>
          <aside className={classNames('features__modal', { open: modalOpen })}>
            <div className="features__modal__content">
              <video
                id="my-video"
                class="video-js vjs-theme-sea"
                controls
                width="100%"
                height="100%"
                preload="none"
                data-setup="{'fluid': true}"
              >
              </video>
              <button className="features__modal__content__close" type="button" onClick={handleModal()}></button>
            </div>
          </aside>
        </section>
      </section>

      <section id="video" className="video">
        <div>
          <header className="header-sec">
            <h5>{t('Lobbyze')}</h5>
            <h3>{t('intro')}</h3>
          </header>
          <div className="video__container">
            <div className="video__container__box">
              <video
                    id="my-video2"
                    class="video-js vjs-theme-sea vjs-16-9"
                    controls
                    preload="auto"
                    data-setup="{'fluid': true}"
                  >
                <source src={i18n.language == 'en' ? 'https://lobbyze-public-misc.s3.us-east-2.amazonaws.com/lobbyze-tutorials/lobbyze_demo_en.mp4': 'https://lobbyze-public-misc.s3.us-east-2.amazonaws.com/lobbyze-tutorials/lobbyze_demo_pt.mp4'} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>

      <footer id="contato" className="footer">
        <header className="header-sec">
          <div className="container">
            <h5>{t('contact')}</h5>
            <h3>
              <Trans i18nKey="questionContactUs" components={{ br: <br /> }} />
            </h3>
          </div>
        </header>
        <div className="footer__content container">
          <aside className="footer__form">
            <ContactForm />
          </aside>
          <aside className="footer__infos">
            <header className="footer__infos__header">
              <a href="index.html">
                <img src={logoLobbyze} alt="Logo Lobbyze" />
              </a>
              <div>
                <div className="footer__infos__others__redes">
                  <a title="Youtube" target="_blank" href="https://www.youtube.com/channel/UCNV4wyNVIW6E5DQAmQILxyQ/featured">
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="26px" height="26px"><path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z" fill="#838CFF"/></svg>
                  </a>
                  <a title="Instagram" target="_blank" href="https://www.instagram.com/lobbyze">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.0083 5.21901C7.34165 5.21901 5.19068 7.37045 5.19068 10.0377C5.19068 12.705 7.34165 14.8565 10.0083 14.8565C12.675 14.8565 14.826 12.705 14.826 10.0377C14.826 7.37045 12.675 5.21901 10.0083 5.21901ZM10.0083 13.1705C8.28505 13.1705 6.87623 11.7656 6.87623 10.0377C6.87623 8.30987 8.28086 6.90493 10.0083 6.90493C11.7358 6.90493 13.1404 8.30987 13.1404 10.0377C13.1404 11.7656 11.7316 13.1705 10.0083 13.1705ZM16.1467 5.0219C16.1467 5.64678 15.6436 6.14585 15.0231 6.14585C14.3983 6.14585 13.8994 5.64259 13.8994 5.0219C13.8994 4.40121 14.4025 3.89795 15.0231 3.89795C15.6436 3.89795 16.1467 4.40121 16.1467 5.0219ZM19.3376 6.16262C19.2663 4.65703 18.9225 3.32339 17.8197 2.2246C16.7212 1.12582 15.3878 0.781923 13.8826 0.706434C12.3312 0.618363 7.68127 0.618363 6.1299 0.706434C4.62884 0.777729 3.29549 1.12162 2.19276 2.22041C1.09002 3.3192 0.750397 4.65284 0.674925 6.15843C0.586874 7.71015 0.586874 12.3611 0.674925 13.9128C0.746204 15.4184 1.09002 16.7521 2.19276 17.8509C3.29549 18.9496 4.62464 19.2935 6.1299 19.369C7.68127 19.4571 12.3312 19.4571 13.8826 19.369C15.3878 19.2977 16.7212 18.9538 17.8197 17.8509C18.9183 16.7521 19.2621 15.4184 19.3376 13.9128C19.4256 12.3611 19.4256 7.71434 19.3376 6.16262ZM17.3333 15.5778C17.0063 16.3998 16.3732 17.0331 15.5472 17.3644C14.3103 17.8551 11.3752 17.7418 10.0083 17.7418C8.64145 17.7418 5.70222 17.8509 4.46951 17.3644C3.6477 17.0373 3.01457 16.404 2.68333 15.5778C2.19276 14.3406 2.30597 11.4049 2.30597 10.0377C2.30597 8.67054 2.19695 5.73066 2.68333 4.49767C3.01037 3.67567 3.6435 3.0424 4.46951 2.71109C5.70641 2.22041 8.64145 2.33364 10.0083 2.33364C11.3752 2.33364 14.3145 2.2246 15.5472 2.71109C16.369 3.03821 17.0021 3.67148 17.3333 4.49767C17.8239 5.73485 17.7107 8.67054 17.7107 10.0377C17.7107 11.4049 17.8239 14.3448 17.3333 15.5778Z"
                        fill="#838CFF"
                      />
                    </svg>
                  </a>
                  <a title="Twitter" target="_blank" href="https://twitter.com/lobbyzepoker">
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9799 4.75279C17.9924 4.93966 17.9924 5.12657 17.9924 5.31344C17.9924 11.0132 13.9404 17.5807 6.53456 17.5807C4.25296 17.5807 2.13347 16.8732 0.350586 15.6452C0.674757 15.6852 0.986418 15.6986 1.32306 15.6986C3.20565 15.6986 4.93869 15.0178 6.3226 13.8565C4.55219 13.8164 3.06853 12.575 2.55735 10.8664C2.80672 10.9064 3.05606 10.9331 3.3179 10.9331C3.67946 10.9331 4.04104 10.8797 4.37765 10.7863C2.53244 10.3858 1.14848 8.65056 1.14848 6.55485V6.50147C1.68457 6.82184 2.30801 7.02206 2.96875 7.04873C1.88406 6.2745 1.17343 4.95301 1.17343 3.45797C1.17343 2.65707 1.37288 1.9229 1.72199 1.28217C3.70436 3.89848 6.68415 5.60706 10.0255 5.79397C9.96313 5.4736 9.92572 5.13992 9.92572 4.8062C9.92572 2.43014 11.7211 0.494629 13.9528 0.494629C15.1123 0.494629 16.1595 1.01522 16.8952 1.85618C17.8053 1.66931 18.678 1.30888 19.451 0.814995C19.1518 1.81616 18.5159 2.65711 17.6806 3.19102C18.491 3.09762 19.2765 2.8573 19.9996 2.52362C19.4511 3.37789 18.7653 4.13872 17.9799 4.75279Z"
                        fill="#838CFF"
                      />
                    </svg>
                  </a>
                  <a title="Discord" target="_blank" href="https://discord.gg/DA4DbJnnCW">
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="25px" height="25px"><path d="M41.626,10.768C37.644,7.567,31.347,7.025,31.08,7.003c-0.42-0.036-0.819,0.202-0.992,0.587c-0.012,0.025-0.15,0.34-0.303,0.833c2.633,0.443,5.867,1.339,8.794,3.155c0.469,0.291,0.613,0.907,0.322,1.377c-0.189,0.305-0.516,0.473-0.851,0.473c-0.18,0-0.362-0.048-0.526-0.15C32.494,10.158,26.209,10,25,10s-7.495,0.158-12.523,3.278c-0.47,0.292-1.086,0.147-1.377-0.322c-0.292-0.47-0.147-1.086,0.322-1.377c2.927-1.815,6.16-2.712,8.794-3.155c-0.154-0.493-0.292-0.808-0.303-0.833c-0.173-0.386-0.571-0.629-0.993-0.587c-0.266,0.021-6.563,0.563-10.598,3.809C6.213,12.76,2,24.152,2,34c0,0.174,0.045,0.344,0.131,0.495c2.909,5.109,10.842,6.447,12.649,6.504C14.791,41,14.801,41,14.812,41c0.319,0,0.62-0.152,0.809-0.411l1.829-2.513c-4.933-1.276-7.453-3.439-7.598-3.568c-0.414-0.365-0.453-0.997-0.087-1.411c0.365-0.414,0.995-0.453,1.41-0.089C11.236,33.062,15.875,37,25,37c9.141,0,13.782-3.953,13.828-3.993c0.414-0.359,1.045-0.323,1.409,0.094c0.364,0.414,0.325,1.043-0.088,1.407c-0.146,0.129-2.666,2.292-7.599,3.568l1.829,2.513C34.568,40.848,34.869,41,35.188,41c0.011,0,0.021,0,0.031-0.001c1.809-0.057,9.741-1.395,12.649-6.504C47.955,34.344,48,34.174,48,34C48,24.152,43.787,12.76,41.626,10.768z M18.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C22,28.209,20.433,30,18.5,30z M31.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C35,28.209,33.433,30,31.5,30z" fill="#838CFF"/></svg>
                  </a>
                </div>
              </div>
            </header>
            <section className="footer__infos__others">
              <div className="footer__infos__others__contents">
                <div className="footer__infos__others__contents__content">
                  <figure>
                    <img src={email} alt="ícone e-mail" />
                  </figure>
                  <div>
                    <h5>{t('email')}</h5>
                    <span>support@lobbyze.com</span>
                  </div>
                </div>
              </div>
            </section>
          </aside>
        </div>
        <h6 className="footer__obs">
          {t('copyright', { year: new Date().getFullYear() })}
          <a href="/tos.html" target="_blank" className="footer__privacy">{t('termsOfService')}</a>
          <a href="/privacy_policy.html" target="_blank" className="footer__privacy">{t('privacyPolicy')}</a>
        </h6>
      </footer>
    </div>
  );
};

export default Home;
